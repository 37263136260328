import React from 'react'
import  './style.less'
import Logo from '../../assets/logo.png'
import Android from '../../assets/android.png'
import Ios from '../../assets/ios.png'
import gril from '../../assets/gril.png'
import man from '../../assets/man.png'
import cup from '../../assets/cup.png'

import Groom from '../../assets/groom.png'
import Location from '../../assets/location.png'
import Talk from '../../assets/talk.png'
import Soul from '../../assets/soul.png'
import HomeShow from '../../assets/home_show.png'
import BeiAn from '../../assets/bei_an_icon.png'

const Home = () => {

    return (
        <div>
            <div className="top_main">
                <div className="topbar">
                    <div className="nav_bar">
                        <div className="logo_region">
                            <img src={ Logo } alt="logo" />
                            <span>O考拉</span>
                        </div>
                    </div>
                </div>
                <div className="main clearfix">
                    <div className="smart">
                        <div className="title">
                            遇见生命中的人，让生活变的丰富多彩
                        </div>
                        <div className="mark">
                            在O考拉，寻找志同道合的朋友或伴侣吧。定位搜索，可以在整个城市搜索，也可以在全国搜索。未来的他（她）一直在等你。
                        </div>
                        <div className="platform clearfix">
                            <a className="android" href="http://mdm.okaola.cn/apk/okaola-release.apk">
                                <img src={ Android } alt="android" />
                                <span>android下载</span>
                            </a>
                            <a className="ios" href="https://apps.apple.com/cn/app/o%E8%80%83%E6%8B%89/id6502873666" target='_blank'>
                                <img src={ Ios } alt="ios" />
                                <span>AppStore下载</span>
                            </a>
                        </div>
                    </div>
                    <div className="exhibit">
                        <img src={ HomeShow } alt="home show" />
                    </div>
                </div>
            </div>
            <div className="feature">
                <div className="groom">
                    <div className="title_region">
                        <img src={Groom} alt="Groom" />
                        <span>推荐</span>
                    </div>
                    <div className="mark">
                        用户每次发布的最新动态，会展示在推荐区域。只会展示你所在城市的推荐信息。
                    </div>
                </div> 
                <div className="location">
                    <div className="title_region">
                        <img src={Location} alt="Location" />
                        <span>附近人</span>
                    </div>
                    <div className="mark">
                        用户可以看到你附近的其他好友，从离你最近的好友依次展示。
                    </div>
                </div>
                <div className="talk">
                    <div className="title_region">
                        <img src={Talk} alt="Talk" />
                        <span>话题</span>
                    </div>
                    <div className="mark">
                        用户可以发布讨论话题，其他用户可以对该话题展开讨论。当然你的个人信息时隐藏的。
                    </div>
                </div>
                <div className="soul_match">
                    <div className="title_region">
                        <img src={Soul} alt="Soul" />
                        <span>灵魂匹配</span>
                    </div>
                    <div className="mark">
                        用户可以通过搜索城市位置信息，或者全国某个位置信息，查询该位置附近的好友。
                    </div>
                </div>
            </div>
            <div className="driver"/>
            <div className="goal">
                <div className="goal_title">
                    <div className="title">
                        慢下来认真交个朋友
                    </div>
                    <div className="mark">
                        无能送你锦绣荣华，一同老去也算风雅！
                        走啊，喝酒去，去见见，这该死的夜晚！
                    </div>
                </div>
                <div className="goal_img">
                    <img src={gril} alt="gril" />
                    <img className='man' src={man} alt="man" />
                    <img className='cup' src={cup} alt="cup" />
                </div>
            </div>
            <div className="footer">
                <div className='level1'>
                    <span className="mail">举报邮箱：zytforget@163.com</span>
                </div>
                <div className='level2'>
                    <span>@2023 O考拉 白凤科技（北京）有限公司</span>
                    <a className='ll' href="https://beian.miit.gov.cn/" without="true" rel="noreferrer" target="_blank" >京ICP备2023018630号-1</a>
                </div>
                <div className='level3'>
                    <span>隐私</span> 
                    <span className='ll'>权限</span>
                    <span className='ll'>版本号：1.0</span>
                    <a className='ll'  href="http://mdm.okaola.cn/other/v1/25f73acf-2e1b-4565-8703-bbcf26fc356fd92cdfc5-bf61-a160-0436-79b183c17a2ad344d4fe-0700-9be3-365d-a7b6bdb89d14.jpg" without="true" rel="noreferrer" target="_blank" >营业执照：91110111MACMY9WH12</a>
                    <a className= 'll beian' without="true" rel="noreferrer" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011102002372">
                        <img className='icon' src={ BeiAn } alt=''/>
                        <p className='num'>京公网安备 11011102002372号</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home